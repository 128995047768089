import {
	START_LOADING,
	LOAD,
	END_LOADING,
	START_LOADING_FROM_OPTIONS,
	LOAD_FROM_OPTIONS,
	END_LOADING_FROM_OPTIONS,
	START_LOADING_TO_OPTIONS,
	LOAD_TO_OPTIONS,
	END_LOADING_TO_OPTIONS,
} from '../constants/search'
import {
	addErrorMessage,
	addMessage,
	addSuccessMessage,
} from '../actions/notification'

const startLoading = () => ({
	type: START_LOADING,
})

const load = payload => ({
	type: LOAD,
	payload,
})

const endLoading = () => ({
	type: END_LOADING,
})

const startLoadingFromOptions = () => ({
	type: START_LOADING_FROM_OPTIONS,
})

const loadFromOptions = options => ({
	type: LOAD_FROM_OPTIONS,
	options,
})

const endLoadingFromOptions = () => ({
	type: END_LOADING_FROM_OPTIONS,
})

const startLoadingToOptions = () => ({
	type: START_LOADING_TO_OPTIONS,
})

const loadToOptions = options => ({
	type: LOAD_TO_OPTIONS,
	options,
})

const endLoadingToOptions = () => ({
	type: END_LOADING_TO_OPTIONS,
})

export const searchTrips = (api, searchData) => dispatch => {
	dispatch(startLoading())

	const url = '/trips/searchRange'

	let isoDate = new Date(searchData.departureDate)
	// console.log('search data >>>', data)
	searchData.departureDate = isoDate.setDate(isoDate.getDate() + 1)
	console.log('search data 2>>>', searchData)
	return api
		.get(url, {
			params: { filter: searchData },
		})
		.then(({ data: { data } }) => {
			console.log('search results >>>>', data)
			dispatch(endLoading())

			if (!data.length) {
				dispatch(load(data))
				dispatch(addErrorMessage('No results found.'))
			} else {
				dispatch(load(data))

				dispatch(addSuccessMessage(`${data.length} result(s) found`))
			}
		})
		.catch(({ message }) => {
			dispatch(endLoading())

			dispatch(addErrorMessage(message))
		})
}

export const fetchFromOptions = (api, date) => dispatch => {
	dispatch(startLoadingFromOptions())

	// const url = "/trips/searchDepDes";
	const url = `/bookingLocations/tripsLocations?departureDate=${date}`

	return api
		.get(url)
		.then(response => {
			dispatch(endLoadingFromOptions())

			const options = response.data.data.map(o => o)

			dispatch(loadFromOptions([...new Set(options)]))
		})
		.catch(({ message }) => {
			dispatch(endLoadingFromOptions())

			dispatch(addErrorMessage(message))
		})
}

export const fetchToOptions = (api, date) => dispatch => {
	dispatch(startLoadingToOptions())

	// const url = "/trips/searchDepDes";
		const url = `/bookingLocations/tripsLocations?departureDate=${date}`

	return api
		.get(url)
		.then(response => {
			dispatch(endLoadingToOptions())

			const options = response.data.data.map(o => o)

			dispatch(loadToOptions([...new Set(options)]))
		})
		.catch(({ message }) => {
			dispatch(endLoadingToOptions())

			dispatch(addErrorMessage(message))
		})
}
