import React from "react";
import { Container, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Landing from "../../static/landingNoBus3.svg";
import Landing2 from "../../static/landingNoBus2.svg";
import Bus from "../../static/bus.svg";
import SearchForm from "../../components/SearchForm";
import { motion } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    margin: "auto",
    // border:'2px solid green',
    paddingTop: theme.spacing(8),
    // paddingBottom: theme.spacing(20),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(8),
      // paddingLeft: theme.spacing(10),
      // paddingRight: theme.spacing(10),
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(8),
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(8),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  root: {
    // maxHeight:"85vh",
    height: "250px",
    paddingTop: "64px",
    marginTop: "-64px",
    backgroundColor: "#fff",
    marginBottom:theme.spacing(4),
    // [theme.breakpoints.down("md")]: {
    //   height: "72vh",
    // },
    // [`${theme.breakpoints.between("md", "md")} and (orientation: landscape)`]: {
    //   height: "80vh",
    // },
    [theme.breakpoints.down("sm")]: {
      height: "580px",
    },
    // [`${theme.breakpoints.between("sm", "sm")} and (orientation: portrait)`]: {
    //   height: "70vh",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   height: "100vh",
    // },
  },
  image: {
    // position: "relative",
    // maxHeight:"70vh",
    height: "50%",
    // border:'2px solid blue',
    // paddingTop: "64px",
    // marginTop: "-64px",
    // backgroundImage: `url(${Landing})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("md")]: {
      height: "67%",
    },

    [theme.breakpoints.down("sm")]: {
      height: "35%",
      // backgroundImage: `url(${Landing2})`,
    },
    // [`${theme.breakpoints.between("sm", "sm")} and (orientation: portrait)`]: {
    //   height: "35%",
    // },
    [theme.breakpoints.down("xs")]: {
      height: "28%",
    },
  },
  busImage: {
    alignItems: "center",
    position: "absolute",
    // backgroundColor:"yellow",
    height: "30%",
    width: "100%",
    display: "flex",
    // border:"2px solid red",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      height: "55%",
    },

    [theme.breakpoints.down("sm")]: {
      height: "30%",
      
    },
    [`${theme.breakpoints.between("sm", "sm")} and (orientation: portrait)`]: {
      height: "30%",
    },
    [theme.breakpoints.down("xs")]: {
      height: "25%",
      // backgroundColor: "#00000060",
    },
  },
  bus: {
    height: "50%",
    maxWidth: "60%",
    maxHeight: "50%",
  },
  formContent: {
    backgroundColor: "#52493b",
    padding: theme.spacing(3),
    borderRadius: "10px",
    // paddingTop: theme.spacing(4),
  },
  overlay: {
    position: "absolute",
    height: "100vh",
    paddingTop: "64px",
    marginTop: "-64px",
    backgroundColor: "#00000000",
    width: "100%",
    [`${theme.breakpoints.between("md", "md")} and (orientation: portrait)`]: {
      height: "40vh",
    },
  },
  header: {
    
    textShadow: "1px 0px 3px #FFFFFF",
    fontWeight: 800,
    // color: "#fff",
    color: theme.palette.primary.main,

    paddingBottom: theme.spacing(5),
    fontSize: "45px",
    paddingLeft: theme.spacing(8),

    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(0),
      fontSize: "36px",
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [`${theme.breakpoints.between("md", "md")} and (orientation: landscape)`]: {
      paddingTop: theme.spacing(0),
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(0),
      fontSize: "28px",
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      color: "#fff",
      textShadow: "4px 1px 13px rgba(0,0,0,0.73)",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(0),
      fontSize: "24px",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(5),
    },
  },
  subHeader: {
    fontWeight: 600,
    marginBottom:'8px',
    color: theme.palette.primary.main,
  },
  formDiv: {
    position: "absolute",
    zIndex: 1000,
    width: "100%",
  },
}));

const Main = () => {
  const classes = useStyles();

  const busVariants = {
    hidden: {
      x: 2000,
    },
    visible: {
      x: 0,
    },
  };
  const headerVariants = {
    hidden: {
      x: 600,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 100,
    },
  };

  return (
    <div className={classes.root}>
      <div className={classes.image}>
        <motion.div
          variants={busVariants}
          initial="hidden"
          animate="visible"
          transition={{ delay: 0.5, duration: 1, ease: "circOut" }}
          className={classes.busImage}
        >
          {/* <motion.div
            variants={headerVariants}
            initial="hidden"
            animate="visible"
            transition={{ delay: 0.5, duration: 1, ease: "circOut" }}
          > */}
          {/* <Typography component="div" className={classes.header}>
            <Box lineHeight={1.3}>Transport Made Easy!</Box>{" "}
          </Typography> */}
          {/* </motion.div> */}
          {/* <img className={classes.bus} src={Bus} alt="booking app kenya" /> */}
        </motion.div>
        <div className={classes.formDiv}>
          <Container className={classes.cardGrid} maxWidth="lg">
            <div className={classes.formContent}>
              {
                <Typography variant="h4" className={classes.subHeader}>
                  {" "}
                  Book your next bus
                </Typography>
              }
              <SearchForm />
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Main;
