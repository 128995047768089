import React, {
	useState,
	useEffect,
	useCallback,
	useMemo,
	forwardRef,
} from 'react'
import {
	Container,
	Grid,
	Typography,
	Button,
	Card,
	CardContent,
	CardActions,
	Modal,
	makeStyles,
	Dialog,
} from '@material-ui/core'
import {
	EventSeat as EventSeatIcon,
	Schedule as ScheduleIcon,
} from '@material-ui/icons'
import axios from 'axios'
import RouteTrips from '../trips'
import SvgIcon from '@material-ui/core/SvgIcon'
import { withStyles } from '@material-ui/core/styles'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

function ClockIcon(props) {
	return (
		<SvgIcon {...props}>
			<path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM13 12H17V14H11V7H13V12Z"></path>
		</SvgIcon>
	)
}

function BusIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="#888686"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			>
				<path d="M8 6v6" />
				<path d="M15 6v6" />
				<path d="M2 12h19.6" />
				<path d="M18 18h3s.5-1.7.8-2.8c.1-.4.2-.8.2-1.2 0-.4-.1-.8-.2-1.2l-1.4-5C20.1 6.8 19.1 6 18 6H4a2 2 0 0 0-2 2v10h3" />
				<circle cx="7" cy="18" r="2" />
				<path d="M9 18h5" />
				<circle cx="16" cy="18" r="2" />
			</svg>
		</SvgIcon>
	)
}
const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
})

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h4" style={{ fontWeight: '700' }}>
				{children}
			</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	)
})
const DialogContent = withStyles(theme => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions)
// const useStyles = makeStyles(theme => ({
//     root: {
//         flexGrow: 1,
//         backgroundColor: '#fff',
//         padding: theme.spacing(4),
//         '& > svg': {
//       margin: theme.spacing(2),
//     },
//     },
//     tripCard: {
//         height: '100%',
//         display: 'flex',
//         flexDirection: 'row',
//         justifyContent: 'space-between',
//         borderRadius: theme.spacing(2),
//         transition: 'transform 0.3s ease',
//         '&:hover': {
//             transform: 'scale(1.03)',
//         },
//     },
//     tripDetails: {
//         display: 'flex',
//         flexDirection: 'row',
//         gap: theme.spacing(1),
//         marginBottom: theme.spacing(2),
//     },
//     detailItem: {
//         display: 'flex',
//         flexDirection:'row',
//         alignItems: 'start',
//         gap: theme.spacing(1),
//     },
//     mainCardInfo:{
//         display:'flex',
//         gap:'32px'
//     },
//     modal:{
//         height:"50vh"
//     }
// }))

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: '#fff',
		padding: theme.spacing(2),
		// '& > svg': {
		// 	margin: theme.spacing(2),
		// },
	},
	tripCard: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		maxWidth: '800px',
		margin: 'auto',
		padding: theme.spacing(1),
		borderRadius: theme.spacing(1),
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[3],
		gap: theme.spacing(3),
		transition: 'transform 0.3s ease',
		'&:hover': {
			transform: 'scale(1.03)',
		},
	},
	mainCardInfo: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(1),
	},
	tripDetails: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(4),
	},
	detailItem: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(1),
		color: theme.palette.text.primary,
	},
	bookButton: {
		whiteSpace: 'nowrap',
		padding: theme.spacing(1, 3),
		fontWeight: theme.typography.fontWeightBold,
	},
}))

const Trips = () => {
	const classes = useStyles()
	const [trips, setTrips] = useState([])
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [open, setOpen] = useState(false)
	const [selectedRoute, setSelectedRoute] = useState(null)

	const fetchFeaturedTrips = useCallback(async () => {
		try {
			setLoading(true)
			const token =
				'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJhNzI5YTI3ZS1hMzI5LTRiYzAtOTYwOC1mZGQxYzM3YWRlZjYiLCJpYXQiOjE1ODAyMTA1MzQsImV4cCI6MTg1MDIxMDUzNH0.OZtT-9gQAOi3RR1pBhR2U9B2RbUMGRG7uGN92J-JO8I'

			const departureDate = new Date()
			departureDate.setHours(23, 59, 59, 999)

			const response = await axios.get(
				`${
					process.env.REACT_APP_BASE_URL
				}routes/seatsSummary?departureDate=${departureDate.toISOString()}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)

			// Only update state if data has changed
			setTrips(prevTrips => {
				const newTrips = response.data.data
				if (JSON.stringify(prevTrips) !== JSON.stringify(newTrips)) {
					return newTrips
				}
				return prevTrips
			})
		} catch (err) {
			setError('Failed to fetch ticket details. Please try again.')
		} finally {
			setLoading(false)
		}
	}, [])

	useEffect(() => {
		fetchFeaturedTrips()
	}, [fetchFeaturedTrips])

	const handleBook = useCallback(route => {
		setSelectedRoute(route)
		setOpen(true)
	}, [])

	const handleClose = useCallback(() => {
		setOpen(false)
	}, [])

	// Memoize the trip rendering to prevent unnecessary re-renders
	const tripList = useMemo(
		() =>
			trips.map((trip, index) => (
				<Grid item xs={12} md={12} key={index}>
					<Card className={classes.tripCard} elevation={3}>
						<CardContent className={classes.mainCardInfo}>
							<Typography variant="h3" color="textSecondary">
								{trip.from} → {trip.to}
							</Typography>

							<div className={classes.tripDetails}>
								<div className={classes.detailItem}>
									{/* <ScheduleIcon color="primary" /> */}
									<BusIcon color="disabled" />
									<Typography variant="h4" style={{ fontWeight: '500' }}>
										{trip.trips.length == 1
											? `${trip.trips.length} Trip`
											: `${trip.trips.length} Trips`}
									</Typography>
								</div>
								<div className={classes.detailItem}>
									{/* <EventSeatIcon color="primary" /> */}
									<Typography variant="h4" style={{ fontWeight: '500' }}>
										{Number(trip.availableSeats)}{' '}
										<span style={{ color: '#767676' }}>Seats available</span>
									</Typography>
								</div>
							</div>
						</CardContent>
						<CardActions>
							<Button
								onClick={() => handleBook(trip)}
								fullWidth
								variant="contained"
								color="primary"
							>
								Book Now
							</Button>
						</CardActions>
					</Card>
				</Grid>
			)),
		[trips, classes, handleBook],
	)

	if (loading) return <Typography>Loading...</Typography>
	if (error) return <Typography color="error">{error}</Typography>

	return (
		<Container className={classes.root}>
			<Grid container spacing={3}>
				{tripList}
			</Grid>
			{/* <Modal
				open={open}
				onClose={handleClose}
				className={classes.modal}
				aria-labelledby="route-details-modal"
				aria-describedby="route-details-description"
			>
				{selectedRoute && <RouteTrips route={selectedRoute} />}
			</Modal> */}
			<Dialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
				maxWidth="lg"
			>
				<DialogTitle id="customized-dialog-title" onClose={handleClose}>
					Available Trips
				</DialogTitle>
				<DialogContent dividers>
					{selectedRoute && <RouteTrips route={selectedRoute} />}
				</DialogContent>
				{/* <DialogActions>
					<Button autoFocus onClick={handleClose} color="primary">
						Save changes
					</Button>
				</DialogActions> */}
			</Dialog>
		</Container>
	)
}

export default Trips
